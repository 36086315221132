<template>
    <div style="width:100%" class="text-center">
        <v-snackbar width="300" multi-line top right :timeout="-1" v-model="showSnackbar">
            <div style="width:100%" class="d-flex flex-column">
                <span v-if="operationType === operationTypes.MOVE_OPERATION" class="font-weight-bold text-uppercase ml-1">file move</span>
                <span v-else-if="operationType === operationTypes.COPY_OPERATION" class="font-weight-bold text-uppercase ml-1">file copy</span>
                <v-divider color="white"></v-divider>
                <div class="mt-1 d-flex align-center justify-space-between">
                    <span>
                        <span v-if="operationType === operationTypes.MOVE_OPERATION" class="font-weight-bold secondary--text">Items to move</span>
                        <span v-else-if="operationType === operationTypes.COPY_OPERATION" class="font-weight-bold secondary--text">Items to copy</span>
                        : {{ selectedFiles.length }} selected</span
                    >
                </div>
                <div class="d-flex justify-start flex-column" v-if="filesToDisplay.length > 0">
                    <ul>
                        <li v-for="file in filesToDisplay" :key="file.fid">
                            <div class="d-flex align-center justify-space-between">
                                <div>
                                    <span class="mr-3">{{ file.short_id }}</span>
                                    <v-btn
                                        :disabled="copyingOrMovingFiles"
                                        @click="$emit('removeFileFromMoveCopyList', { fid: file.fid })"
                                        class="ma-0 pa-0"
                                        small
                                        icon
                                        color="white"
                                        ><v-icon small>clear</v-icon></v-btn
                                    >
                                </div>
                                <v-tooltip v-if="alreadyExistingfiles.includes(file.fid)" left>
                                    <template v-slot:activator="{ on }">
                                        <v-icon color="error" v-on="on">
                                            info
                                        </v-icon>
                                    </template>
                                    <span>File already exists in the target directory</span>
                                </v-tooltip>
                            </div>
                        </li>
                    </ul>
                    <a v-if="selectedFiles.length > 3 && expandFileList === false" @click="expandFileList = true" class="secondary--text caption ml-3"
                        >show all</a
                    >
                    <a v-else-if="selectedFiles.length > 3 && expandFileList === true" @click="expandFileList = false" class="secondary--text caption ml-3"
                        >show less</a
                    >
                </div>
                <div class="d-flex justify-start flex-column">
                    <span
                        ><span v-if="operationType === operationTypes.MOVE_OPERATION" class="font-weight-bold secondary--text">Move to</span
                        ><span v-else-if="operationType === operationTypes.COPY_OPERATION" class="font-weight-bold secondary--text">Copy to</span>:
                        <v-icon color="white" small>folder</v-icon>{{ $store.state.snapshotStore.pathArray.join('/') }}</span
                    >
                    <span class="caption white--text font-italic"> <sup>*</sup>Navigate within the file browser to select the desired target location. </span>
                </div>
                <div class="d-flex justify-end mt-1">
                    <v-btn :disabled="copyingOrMovingFiles" color="white" class="white--text ma-0" small @click="clearFiles" text>
                        cancel
                    </v-btn>
                    <v-btn
                        :disabled="!selectedFiles.length"
                        :loading="copyingOrMovingFiles"
                        color="white"
                        class="white--text ml-1"
                        small
                        outlined
                        @click="moveSelectedFiles()"
                        v-if="operationType === operationTypes.MOVE_OPERATION"
                    >
                        move
                    </v-btn>
                    <v-btn
                        :disabled="!selectedFiles.length"
                        :loading="copyingOrMovingFiles"
                        color="white"
                        class="white--text ml-1"
                        small
                        outlined
                        @click="copySelectedFiles()"
                        v-if="operationType === operationTypes.COPY_OPERATION"
                    >
                        copy
                    </v-btn>
                </div>
            </div>
        </v-snackbar>
    </div>
</template>

<script>
import { enumsData } from '@/mixins/enums'

export default {
    name: 'FileMoveAndCopy',
    mixins: [enumsData],
    props: {
        selectedFiles: Array,
        operationType: String,
        folderTypeSelected: String
    },
    data() {
        return {
            showSnackbar: false,
            expandFileList: false,
            copyOfSelectedFiles: null,
            copyingOrMovingFiles: false,
            alreadyExistingfiles: [],
            operationTypes: {
                COPY_OPERATION: 'copy',
                MOVE_OPERATION: 'move'
            }
        }
    },
    methods: {
        clearFiles() {
            this.alreadyExistingfiles = []
            this.$emit('clearCopyAndMoveFiles')
        },
        moveSelectedFiles: function() {
            if (this.$props.selectedFiles.length) {
                this.$data.copyingOrMovingFiles = true
                let patchBody
                if (this.$props.folderTypeSelected === this.$data.folderTypesLabels.PERSONAL_FILES) {
                    patchBody = { local_path: `${this.$store.state.userInfo.uid}${this.$store.state.snapshotStore.pathArray.join('/')}`, area: 'home' }
                } else {
                    patchBody = { local_path: this.$store.state.snapshotStore.pathArray.join('/'), area: 'files' }
                }
                let numFilesToMove = this.$props.selectedFiles.length
                let numFilesMovedSuccessfully = 0
                this.alreadyExistingfiles = []
                var existingFiles = []
                this.$data.copyOfSelectedFiles.forEach(file => {
                    this.$axios
                        .patch(`/files/${file.fid}`, patchBody, { timeout: 30000 })
                        .then(response => {
                            numFilesMovedSuccessfully += 1
                            this.$store.dispatch('showSnackBar', {
                                snackBarText: `Moving files, please wait.....`,
                                snackBarTimeout: 15000,
                                snackBarIcon: 'info'
                            })
                            this.$data.copyOfSelectedFiles = this.$data.copyOfSelectedFiles.filter(singleFile => singleFile.fid !== file.fid)
                            this.$emit('removeFileFromMoveCopyList', { fid: file.fid })
                        })
                        .catch(error => {
                            if (error.response && error.response.data && error.response.data.reason.includes('already exists')) {
                                existingFiles.push(file.fid)
                            }
                        })
                        .finally(() => {
                            numFilesToMove -= 1
                            if (numFilesToMove === 0) {
                                if (this.$data.copyOfSelectedFiles.length === 0) {
                                    this.$store.dispatch('showSnackBar', {
                                        snackBarText: `File(s) successfully moved!`,
                                        snackBarTimeout: 5000,
                                        snackBarIcon: 'check_circle'
                                    })
                                } else if (numFilesMovedSuccessfully === 0) {
                                    this.$store.dispatch('showSnackBar', {
                                        snackBarText: `Failed to move the files, please try again later or contact support@alphacruncher.com.`,
                                        snackBarTimeout: 10000,
                                        snackBarIcon: 'error'
                                    })
                                } else {
                                    this.$store.dispatch('showSnackBar', {
                                        snackBarText: `Failed to move ${this.$data.copyOfSelectedFiles.length} files. Review above and try again.`,
                                        snackBarTimeout: 15000,
                                        snackBarIcon: 'error'
                                    })
                                }
                                if (numFilesMovedSuccessfully > 0) {
                                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: this.$route.params.snid, setFetchingStatus: true })
                                }
                                if (existingFiles.length) {
                                    this.$data.alreadyExistingfiles = existingFiles
                                }
                                this.$data.copyingOrMovingFiles = false
                            }
                        })
                })
            }
        },
        copySelectedFiles: function() {
            if (this.$props.selectedFiles.length) {
                this.$data.copyingOrMovingFiles = true
                var apiURL
                let numFilesToCopy = this.$props.selectedFiles.length
                let numFilesCopiedSuccessfully = 0
                const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
                const successMessage = `Files succesfully submitted for copying, you can track progress <a href="${userTasksRoute}">here</a>`
                this.$data.copyOfSelectedFiles.forEach(file => {
                    let postBody
                    if (this.$props.folderTypeSelected === this.$data.folderTypesLabels.PERSONAL_FILES) {
                        postBody = { local_path: `${this.$store.state.userInfo.uid}${this.$store.state.snapshotStore.pathArray.join('/')}`, area: 'home' }
                    } else {
                        postBody = { local_path: this.$store.state.snapshotStore.pathArray.join('/'), area: 'files' }
                    }
                    apiURL = `/files/${file.fid}/duplicate_async`
                    this.$axios
                        .post(apiURL, postBody)
                        .then(response => {
                            numFilesCopiedSuccessfully += 1
                            this.$store.dispatch('showSnackBar', {
                                snackBarText: `Copying file ${file.short_id}`,
                                snackBarTimeout: 15000,
                                snackBarIcon: 'info'
                            })
                            this.$data.copyOfSelectedFiles = this.$data.copyOfSelectedFiles.filter(singleFile => singleFile.fid !== file.fid)
                            this.$emit('removeFileFromMoveCopyList', { fid: file.fid })
                        })
                        .finally(() => {
                            numFilesToCopy -= 1
                            if (numFilesToCopy === 0) {
                                if (this.$data.copyOfSelectedFiles.length === 0) {
                                    this.$store.dispatch('showSnackBar', {
                                        snackBarText: successMessage,
                                        snackBarTimeout: 10000,
                                        snackBarIcon: 'check_circle'
                                    })
                                } else if (numFilesCopiedSuccessfully === 0) {
                                    this.$store.dispatch('showSnackBar', {
                                        snackBarText: `Failed to copy the files, please try again later or contact support@alphacruncher.com`,
                                        snackBarTimeout: 10000,
                                        snackBarIcon: 'error'
                                    })
                                } else {
                                    this.$store.dispatch('showSnackBar', {
                                        snackBarText: `Failed to copy ${this.$data.copyOfSelectedFiles.length} files. Review above and try again.`,
                                        snackBarTimeout: 15000,
                                        snackBarIcon: 'error'
                                    })
                                }
                                if (numFilesCopiedSuccessfully > 0) {
                                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: this.$route.params.snid, setFetchingStatus: true })
                                }
                                this.$store.dispatch('userStore/fetchUserTasks')
                                this.$data.copyingOrMovingFiles = false
                            }
                        })
                })
            }
        }
    },
    computed: {
        filesToDisplay() {
            if (this.$data.copyOfSelectedFiles && this.$data.expandFileList === false) {
                return this.$data.copyOfSelectedFiles.slice(0, 3)
            } else if (this.$data.copyOfSelectedFiles) {
                return this.$data.copyOfSelectedFiles
            }
            return []
        }
    },
    watch: {
        copyOfSelectedFiles: function(nextVal) {
            if (nextVal && nextVal.length > 0 && this.$data.showSnackbar === false) {
                this.$data.showSnackbar = true
            } else if (!nextVal.length && this.$data.showSnackbar === true) {
                this.$data.showSnackbar = false
            }
        },
        selectedFiles: function(nextVal) {
            this.$data.copyOfSelectedFiles = nextVal
            this.alreadyExistingfiles = []
        },
        operationType: function(nextVal) {
            if (nextVal === this.operationTypes.COPY_OPERATION) {
                this.alreadyExistingfiles = []
            }
        }
    }
}
</script>
